<template>
  <vx-card title="Cash Bank Management">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Deposit Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="depositDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.depositDate = { startDate: null, endDate: null };
              }
            "
          ></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Collection Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="mt-4 flex justify-between">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="collectDate"
            :linkedCalendars="true"
            v-if="currentTab > 0"
          >
            <template v-slot:input="picker">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <datepicker
            class="w-full h-full"
            name="Collection Date"
            v-model="singleCollectDate"
            placeholder="Select Collection date"
            :inline="false"
            :disabled-dates="{ from: new Date() }"
            v-else
          />
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="removeCollectionDate"
          ></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%" v-if="isGiroTab">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Giro Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="giroDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.giroDate = { startDate: null, endDate: null };
              }
            "
          ></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <!-- <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <multiselect
            class="w-full"
            v-model="territory"
            :options="optionTerritory"
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="name"
            label="name"
            :disabled="false"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title"
                  >{{ dt.option.code }} - {{ dt.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title"
                  >{{ dt.option.code }} - {{ dt.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.territory = null;
                // this.territoryID = 0
              }
            "
          ></vs-button>
        </div>
      </div> -->
      <div class="ml-4 w-full">
        <operating-unit-filter @onShow="onShow" :full="true" />
      </div>
    </div>
    <!-- <vs-button class="mb-2" @click="handleOpenForm">Create</vs-button> -->
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/4 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-tabs :color="colorx" v-model="currentTab">
          <!-- <vs-tab @click="colorx = 'danger'" label="Proposal Approved">
            <div class="con-tab-ejemplo">
              <proposal />
            </div>
          </vs-tab> -->
          <vs-tab @click="changeTab(false)" label="Cash">
            <div class="con-tab-ejemplo">
              <cash
                ref="open"
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
                :singleCollectDate="singleCollectDate"
              />
            </div>
          </vs-tab>
          <vs-tab @click="changeTab(true)" label="Giro">
            <div class="con-tab-ejemplo">
              <giro
                ref="open"
                :collectionDate="collectDate"
                :giroDate="giroDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab>
          <vs-tab @click="changeTab(false)" label="Draft">
            <div class="con-tab-ejemplo">
              <open
                ref="open"
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
                :isManual="isManual"
              />
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'warning'" label="Execution">
            <div class="con-tab-ejemplo">
              <execution
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab> -->
          <!-- <vs-tab @click="colorx = 'success'" label="Mapping">
            <div class="con-tab-ejemplo">
              <mapping :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab> -->
          <vs-tab @click="changeTab(false)" label="Waiting Approval">
            <div class="con-tab-ejemplo">
              <waiting-approval
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="Waiting Reverse">
            <div class="con-tab-ejemplo">
              <waitingReverse
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="Waiting Reconcile">
            <div class="con-tab-ejemplo">
              <waiting
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab>
          <vs-tab @click="changeTab(false)" label="Reconcile">
            <div class="con-tab-ejemplo">
              <recon
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab>
          <vs-tab @click="changeTab(false)" label="Failed">
            <div class="con-tab-ejemplo">
              <failed
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab>
          <vs-tab @click="changeTab(false)" label="Canceled">
            <div class="con-tab-ejemplo">
              <cancel
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab>
          <vs-tab @click="changeTab(false)" label="Rejected">
            <div class="con-tab-ejemplo">
              <reject
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab>
          <vs-tab @click="changeTab(false)" label="Reversed">
            <div class="con-tab-ejemplo">
              <reversed
                :collectionDate="collectDate"
                :depositDate="depositDate"
                :isReload="isReload"
              />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-3/4 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <create @close="handleClose" />
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import Datepicker from "vuejs-datepicker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import open from "./open/open.vue";
import execution from "./execution/execution.vue";
import cancel from "./cancel/cancel.vue";
import reject from "./reject/reject.vue";
import mapping from "./mapping/mapping.vue";
import waiting from "./waiting_recon/waiting.vue";
import waitingReverse from "./waiting_reverse/waiting.vue";
import recon from "./recon/recon.vue";
import failed from "./failed/failed.vue";
import create from "./form-create.vue";
import reversed from "./reversed/reversed.vue";
import cash from "./cash/cash.vue";
import giro from "./giro/giro.vue";
import operatingUnitFilter from "../../../../components/operatingUnitFilter/operatingUnitFilter.vue";
import waitingApproval from "./waiting_approval/index.vue";

export default {
  components: {
    open,
    create,
    execution,
    cancel,
    mapping,
    recon,
    waiting,
    failed,
    reversed,
    cash,
    giro,
    reject,
    DateRangePicker,
    datepicker: Datepicker,
    "operating-unit-filter": operatingUnitFilter,
    "waiting-approval": waitingApproval,
    waitingReverse,
  },
  data() {
    return {
      isGiroTab: false,
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      colorx: "red",
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      postingDate: {},
      territory: null,
      territoryID: 0,
      optionTerritory: [],
      depositDate: {
        startDate: null,
        endDate: null,
      },
      giroDate: {
        startDate: null,
        endDate: null,
      },
      collectDate: {
        startDate: null,
        endDate: null,
      },
      isReload: false,
      currentTab: 0,
      singleCollectDate: new Date(),
      isManual: false,
    };
  },
  mounted() {
    const path = this.$router.currentRoute.path;
    if (path.includes("manual")) {
      this.isManual = true;
    }
  },
  methods: {
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
      this.$refs.open.closeDetail();
    },
    changeTab(isGiroTab) {
      console.log(isGiroTab, "isGiroTab");

      this.isGiroTab = isGiroTab;
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
    getOptionTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionTerritory = resp.data.records;
            console.log(this.optionTerritory);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    onShow() {
      this.isReload = true;
      this.$nextTick(() => {
        this.isReload = false;
      });
    },
    removeCollectionDate() {
      if (this.currentTab === 0) {
        this.singleCollectDate = null;
      } else {
        this.collectDate = { startDate: null, endDate: null };
      }
    },
  },
  // destroyed() {
  //   this.$store.commit("operatingUnit/resetState");
  // },
  watch: {
    currentTab() {
      if (this.currentTab === 0) {
        this.singleCollectDate = new Date();
      } else {
        this.singleCollectDate = null;
      }
    },
  },
};
</script>
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
